import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductModel,orderTotal } from '@app/domain/ProductModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';

export interface ProductState {
  loading: boolean;
  productorders: ProductModel[] | [];
  totalOrders: orderTotal[] | [];
}

const initialState: ProductState = {
  loading: false,
  productorders: [],
  totalOrders:[]
};

export const productOrderList = createAsyncThunk("/products/productOrder-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<ProductModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ProductOrder/GetAllProductOrderTotalList`,
        {
          params: {
            companyId: getLoggedInCompanyId(),
          },
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const totalOrderList = createAsyncThunk("/products/totalOrder-list",async (Year, thunkApi) => {
    try {
      const response = await axios.get<orderTotal[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ProductOrder/GetTotalOrderDetailsByMonth`,
        {
          params: {
            CompanyId: getLoggedInCompanyId(),
            Year: Year
          },
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

const productorderSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(productOrderList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(productOrderList.fulfilled, (state, action: PayloadAction<ProductModel[]>) => {
      state.loading = false;
      state.productorders = action.payload;
    });
    builder.addCase(productOrderList.rejected, (state, action) => {
      state.loading = false;
      state.productorders = [];
    });
    // Total Order List
    builder.addCase(totalOrderList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(totalOrderList.fulfilled, (state, action: PayloadAction<orderTotal[]>) => {
      state.loading = false;
      state.totalOrders = action.payload;
    });
    builder.addCase(totalOrderList.rejected, (state, action) => {
      state.loading = false;
      state.totalOrders = [];
    });
   },
});

export const thunks = {
  productOrderList,
};

export default productorderSlice.reducer;
