import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { ZoneModel } from '@app/domain/ZoneModel';
import {ZoneAreaModel} from '@app/domain/ZoneModel';

export interface CheckoutConfigState {
  loading: boolean;
  deliveryArea: ZoneAreaModel | null;
}

const initialState: CheckoutConfigState = {
  loading: false,
  deliveryArea: null,
};

export const deliveryAreaList = createAsyncThunk(
  'deliveryArea/deliveryArea-list',
  async (_, thunkApi) => {
    try {
      const response = await axios.get<ZoneAreaModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/CheckoutSetup/GetConfiguration`,
        {
          params: { companyId: getLoggedInCompanyId() },
          headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
        },
      );
      console.log(response, "response:");
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);

export const updateDeliveryArea = createAsyncThunk(
  'deliveryArea/update-deliveryArea',
  async (data: ZoneModel, thunkApi) => {
    try {
      console.log("Updating delivery zone with data:", data); 

      const response = await axios.put<ZoneModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/CheckoutSetup/UpdateConfiguration`,
        data,
        {
          headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
        }
      );

      console.log("Update response:", response.data); 
      return response.data;
    } catch (error) {
      console.error("Update failed:", error); 
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);

const checkoutConfigSlice = createSlice({
  name: 'checkoutConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deliveryAreaList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deliveryAreaList.fulfilled, (state, action: PayloadAction<ZoneAreaModel>) => {
      state.loading = false;
      state.deliveryArea = action.payload;
    });
    builder.addCase(deliveryAreaList.rejected, (state) => {
      state.loading = false;
      state.deliveryArea = null;
    });
  },
});

export const thunks = {
  deliveryAreaList
};

export default checkoutConfigSlice.reducer;
