import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductModel,ProductDetailModel, Product, BaseResponse } from '@app/domain/ProductModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { ParentCategoryModel } from '@app/domain/CategoryModel';

export interface ParentCategoryState {
  loading: boolean;
  ParentCategorys: ParentCategoryModel[] | [];
  CategorysProduct: ParentCategoryModel[] | [];
  ParentCategory: ParentCategoryModel | null;

}

const initialState: ParentCategoryState = {
  loading: false,
  ParentCategorys: [],
  CategorysProduct: [],
  ParentCategory: null,
};

export const addCategory = createAsyncThunk(
  "/category/add-parent-category",
  async (values, thunkApi) => {
    try {
      const response = await axios.post<ParentCategoryModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Category/SaveCategory`,
        {...values},
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const parentcategoryList = createAsyncThunk(
  "/category/parentcategory-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<ParentCategoryModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Category/GetAllCategoryList`,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const categoryProductList = createAsyncThunk(
  "/category/categoryProduct-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<ParentCategoryModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Category/GetAllCategoryProductList`,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const UpdateParentCategory = createAsyncThunk(
  "parent-category/update-category",
  async (values, thunkApi) => {
    console.log(values);
    try {
      const response = await axios.put<ParentCategoryModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Category/UpdateCategory`,
         {...values},
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const deleteParentCategory = createAsyncThunk('category/delete-category', async (id, thunkApi) => {
  try {
    const response = await axios.delete<ParentCategoryModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Category/DeleteCategory`,
      {
        params: {
          Id: id
        },
        headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const ParentCategoryDetails = createAsyncThunk(
  "products/view-product",
  async (id, thunkApi) => {
    try {
      const response = await axios.get<ParentCategoryModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Category/GetCategoryDetailsById`,{
          params: 
          {
            Id: id
          }
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);
  
const ParentCategorySlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(parentcategoryList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(parentcategoryList.fulfilled,(state, action: PayloadAction<ParentCategoryModel[]>) => {
          state.loading = false;
          state.ParentCategorys = action.payload;
      });
      builder.addCase(parentcategoryList.rejected, (state, action) => {
        state.loading = false;
        state.ParentCategorys = [];
      });
      builder.addCase(categoryProductList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(categoryProductList.fulfilled,(state, action: PayloadAction<ParentCategoryModel[]>) => {
          state.loading = false;
          state.CategorysProduct = action.payload;
      });
      builder.addCase(categoryProductList.rejected, (state, action) => {
        state.loading = false;
        state.CategorysProduct = [];
      });
      builder.addCase(ParentCategoryDetails.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(ParentCategoryDetails.fulfilled,(state, action: PayloadAction<ParentCategoryModel>) => {
          state.loading = false;
          state.ParentCategory = action.payload;
      });
      builder.addCase(ParentCategoryDetails.rejected, (state, action) => {
        state.loading = false;
        state.ParentCategory = null;
      });
  }
})

export const thunks = {
  parentcategoryList,
  categoryProductList,
  ParentCategoryDetails,
  UpdateParentCategory,
  addCategory
};

export default ParentCategorySlice.reducer;
