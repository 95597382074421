import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { ZoneModel } from '@app/domain/ZoneModel';
import {ZoneAreaModel} from '@app/domain/ZoneModel';

export interface DeliveryZonesState {
  loading: boolean;
  deliveryZones: ZoneModel[];
  deliveryZone: ZoneModel | null;
  deliveryArea: ZoneAreaModel | null;
}

const initialState: DeliveryZonesState = {
  loading: false,
  deliveryZones: [],
  deliveryZone: null,
  deliveryArea: null,
};

export const deliveryZoneList = createAsyncThunk(
  'deliveryZone/deliveryZone-list',
  async (_, thunkApi) => {
    try {
      const response = await axios.get<ZoneModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/DeliveryZone/GetAllDeliveryZoneList`,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        },
      );
      console.log(response, "response:");

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);

export const addDeliveryZone = createAsyncThunk(
  'deliveryZone/add-deliveryZone',
  async (values, thunkApi) => {
    try {
      const response = await axios.post<ZoneModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/DeliveryZone/SaveDeliveryZone`,
        { ...values},
        { 
          headers: {
          "Accept":"application/json",
          "Authorization": 'Bearer ' + readToken(),
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);

  export const updateDeliveryZone = createAsyncThunk(
    'deliveryZone/update-deliveryZone',
    async (data: ZoneModel, thunkApi) => {
      try {
        console.log("Updating delivery zone with data:", data); 
  
        const response = await axios.put<ZoneModel>(
          `${process.env.REACT_APP_BASE_URL}/Ecommerce/DeliveryZone/UpdateDeliveryZone`,
          data, 
          {
            headers: {
              "Accept":"application/json",
              "Authorization": 'Bearer ' + readToken(),
            },
          }
        );
  
        console.log("Update response:", response.data); 
        return response.data;
      } catch (error) {
        console.error("Update failed:", error); 
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
      }
    }
  );
  
  

export const deleteDeliveryZone = createAsyncThunk(
  'deliveryZone/delete-deliveryZone',
  async (id, thunkApi) => {
    try {
      const response = await axios.delete<ZoneModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/DeliveryZone/DeleteDeliveryZone`,
        {
          params: { Id: id },
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);

export const deliveryZonesDetails = createAsyncThunk(
  'deliveryZone/view-deliveryZone',
  async (id, thunkApi) => {
    try {
      const response = await axios.get<ZoneModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/DeliveryZone/GetDeliveryZoneDetailsById`,
        { params: { Id: id } }
      );
      console.log(response, "responses:");

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);

export const deliveryAreaList = createAsyncThunk(
  'deliveryArea/deliveryArea-list',
  async (_, thunkApi) => {
    try {
      const response = await axios.get<ZoneAreaModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/CheckoutSetup/GetDeliveryArea`,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        },
      );
      console.log(response, "response:");
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);

export const updateDeliveryArea = createAsyncThunk(
  'deliveryArea/update-deliveryArea',
  async (data: ZoneModel, thunkApi) => {
    try {
      console.log("Updating delivery zone with data:", data); 

      const response = await axios.put<ZoneModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/CheckoutSetup/UpdateDeliverArea`,
        data,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        }
      );

      console.log("Update response:", response.data); 
      return response.data;
    } catch (error) {
      console.error("Update failed:", error); 
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);

const deliveryZoneSlice = createSlice({
  name: 'deliveryZones',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deliveryZoneList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deliveryZoneList.fulfilled, (state, action: PayloadAction<ZoneModel[]>) => {
      state.loading = false;
      state.deliveryZones = action.payload;
    });
    builder.addCase(deliveryZoneList.rejected, (state) => {
      state.loading = false;
      state.deliveryZones = [];
    });

    builder.addCase(deliveryZonesDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deliveryZonesDetails.fulfilled, (state, action: PayloadAction<ZoneModel>) => {
      state.loading = false;
      state.deliveryZone = action.payload;
    });
    builder.addCase(deliveryZonesDetails.rejected, (state) => {
      state.loading = false;
      state.deliveryZone = null;
    });
    builder.addCase(deliveryAreaList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deliveryAreaList.fulfilled, (state, action: PayloadAction<ZoneAreaModel>) => {
      state.loading = false;
      state.deliveryArea = action.payload;
    });
    builder.addCase(deliveryAreaList.rejected, (state) => {
      state.loading = false;
      state.deliveryArea = null;
    });
  },
});

export const thunks = {
  deliveryZoneList,
  addDeliveryZone,
  updateDeliveryZone,
  deleteDeliveryZone,
  deliveryZonesDetails,
  deliveryAreaList
};

export default deliveryZoneSlice.reducer;
